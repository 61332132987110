import { CSSResult, LitElement, css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '@refinitiv-ui/elements/button';
import '../components/login-frame.js';
import '../components/heimdall-loader.js';
import '../components/heimdall-error.js';
import type { InteractionState } from '../internal.js';
import { createLoginWeb$, selectPreferredIdp$ } from '../login-web.js';
import '../theme.js';

@customElement('heimdall-login')
export class HeimdallLogin extends LitElement {
  @state()
  _isAuthenticated = false;

  @state()
  _interactionState: InteractionState = {
    state: 'STAND_BY',
    reason: 'Initial state',
  };

  constructor() {
    super();
  }

  firstUpdated() {
    createLoginWeb$().subscribe((interactionState) => {
      this._interactionState = interactionState;
    });
  }

  render() {
    return html`<login-frame> ${this.getFrameContent()} </login-frame>`;
  }

  getFrameContent() {
    if (this._interactionState.state === 'REQUIRES_IDP_SELECTION') {
      return html`
        <h2>Select preferred Idp:</h2>
        ${this._interactionState.availableIdps.map(
          (idp) =>
            html`<ef-button
              class="button"
              @click=${() => selectPreferredIdp$.next(idp)}
            >
              ${idp}
            </ef-button>`
        )}
      `;
    }

    if (this._interactionState.state === 'ERROR') {
      return html`<heimdall-error
        .error=${this._interactionState.error}
      ></heimdall-error>`;
    }

    return html`<heimdall-loader />`;
  }

  static get styles(): CSSResult {
    return css`
      .button {
        margin: 0 10px 10px 0;
      }
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'heimdall-login': HeimdallLogin;
  }
}
